import Banner from "./components/Banner";
import Design from "./components/Design";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="relative w-full h-screen overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-screen bg-center bg-no-repeat bg-cover -z-10 bg-banner-bg">
        <Design />
      </div>
      <div className="max-w-6xl mx-auto text-white">
        {/* Header */}
        <Header />
        {/* Banner */}
        <Banner />
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;
