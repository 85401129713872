import React from "react";
import logoSVG from "../assets/logo.svg";

const Banner = () => {
  return (
    <div>
      <div className="flex items-center justify-between w-full">
          <img className="w-full" src={logoSVG} alt="banner" />
      </div>
      <div className="flex items-center justify-center w-full">
        <div className="w-8/12 m-auto overflow-hidden bg-white rounded-lg shadow opacity-80">
          <div className="px-4 py-5 text-black sm:p-6">
            <p><strong>Hier entsteht die Webseite des Projektes »KMU.kompetent.sicher.«</strong></p>

            <p>Das Projekt <em>»KMU.kompetent.sicher.«</em> zielt darauf ab, eine integrierte NIS2-Kompetenzplattform für kleine und mittlere Unternehmen (KMU) zu entwickeln. Diese Plattform wird die Testung und Weiterbildung der IT-Sicherheitskompetenzen sowohl von Führungskräften als auch Mitarbeitenden in KMU nahtlos verbinden. Die Plattform baut auf den Vorprojekten »ITS.Kompetent« und »KMU. Einfach Sicher.« auf, erweitert deren Inhalte und integriert sie in ein umfassendes System, das den Anforderungen der NIS2-Richtlinie entspricht.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
