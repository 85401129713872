import React from "react";

const Header = () => {
  return (
    <div className="w-full text-white p-10 lg:p-0 lg:py-10 flex justify-between items-center border-b-[1px] border-b-white">
      <div>
        <h1 className="text-2xl font-bold">KMU.kompetent.sicher.</h1>
      </div>
      <div>
      </div>
      <div>
      <button
            className="px-6 py-1 text-base font-semibold cursor-pointer bg-biscay-600 rounded-xl"
            onClick={(e) => {
              e.preventDefault();
              window.open("mailto:info@kmu-kompetent-sicher.de", "_blank", "noreferrer");
            }}>
          Kontakt
        </button>
      </div>
    </div>
  );
};

export default Header;
