import React from "react";

const Footer = () => {
  return (
    <div className="sticky top-[100vh] w-full text-white p-10 flex justify-between items-center">
        <ul className="flex gap-6 text-sm font-semibold">
          <li className="cursor-pointer" onClick={(e) => {
              e.preventDefault();
              window.open("https://coactum.de/imprint", "_blank", "noreferrer");
            }}>Impressum</li>
          <li className="cursor-pointer" onClick={(e) => {
              e.preventDefault();
              window.open("https://coactum.de/privacy", "_blank", "noreferrer");
            }}>Datenschutz</li>
        </ul>
    </div>
  );
};

export default Footer;
